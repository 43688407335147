.App {
  align-items: center;
}

.App-logo {
  height:80px;
  width:80px;
  pointer-events: none; 
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
.header{
  width: 90%;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
}
.App-header {
  background-color: #FFFFFF ;
  height: 90px;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-shrink: 0; 
  padding-left: 50px;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);

}
.App-footer {
  height: 90px;
  width:100%;
  background-color: #1A1C1E ;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 0;
  margin-bottom: 0;
    font-size: 14px;
    color: #9e9e9e;
    font-weight: 400;
    font-family: "Roboto",sans-serif;
}
.App-link {
  color: #61dafb;
}
.content { 
 
  overflow: auto; 
  padding:0px 50px 20px 50px;


}
.sample-text-area {
    background: #fff;
    padding: 20px 0px 0px 0;
}
.text-heading {
    margin-bottom: 30px;
    font-size: 24px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-20 {
    margin-bottom: 20px;
}
.bradcam_bg {
    background-image: url(./assets/breadcam_bg.png);
}
.breadcam_area {
    padding: 85px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.breadcam_area .bradcam_text h3 {
    font-size: 60px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 10px;
}
.innerContent{
  align-items: center;
  justify-content: flex-start;
}
.container{
  height: 100%;
  flex-direction: row;
  padding-bottom: 40px;
}
/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.autocompleteinput {
       border: 1px solid #e5e6e9;
       width:100%;
    border-radius: 0px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
       outline: 0;

    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.imageBox{
  height: 200px;
  width:200px;
  border: 1px;
  border-style: double;
  align-items: center;
}